
.set-service-goods{
  margin-top: 20px;
  .service-goods-btn{
    text-align: right;
  }
  .service-goods-table{
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .goods-table-header{
      padding: 0 30px;
      display: flex;
      background: #EEEEEE;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      span{
        color: #666666;
        font-size: 16px;
      }
      .table-header-item{
        text-align: center;
        flex: 1;
        width: 1%;
        &:first-of-type{
          text-align: left;
        }
      }
    }
    .goods-table-body{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-body-item{
        display: flex;
        flex-direction: column;
        .body-item-top, .body-item-bottom{
          padding: 0 30px;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #EEEEEE;
          .item-top-item, .item-bottom-item{
            width: 1%;
            flex: 1;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            ::v-deep .el-date-editor{
              .el-input__inner{
                padding: 0 10px;
              }
              .el-input__prefix{
                display: none;
              }
            }
            //&.item-bottom-item:last-of-type{
            //  justify-content: flex-end;
            //  .el-link{
            //    margin-right: 6px;
            //  }
            //}
          }
        }
      }
    }
  }
}
